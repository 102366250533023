.home-page-main-div {
  position: relative;
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  box-sizing: border-box;
  /* overflow: hidden; */

  /* background: url('../assets/background/topBackground.webp'); */
}

.home-page-main-div .main-background {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

@media (max-width: 768px) {
  .home-page-main-div {
    padding: 10rem 0 0 0;
  }
}
