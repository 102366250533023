.viewtts-button {
  width: 15rem;
  height: 4.2rem;
  border-radius: 0.6875rem;
  background: #e25858;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;
  cursor: pointer;
  font-family: "Accent";
}

.viewtts-button:hover {
  background: #f37777;
}

.group-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin-bottom: 35rem;
}
.group-section img {
  position: absolute;
  top: 0rem;
  width: 55%;
}
.group-heading {
  display: flex;
  justify-items: space-around;
  align-items: center;
  gap: 2rem;
}
.left-line {
  width: 18rem;
  height: 0.15rem;
  background: linear-gradient(to left, #e25858, #4e2a32);
}
.right-line {
  width: 18rem;
  height: 0.15rem;
  background: linear-gradient(to right, #e25858, #4e2a32);
}
.group-section .group-section-text {
  position: absolute;
  top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.group-section .group-section-text .text-paragraph {
  text-align: center;
  max-width: 50rem;
  color: rgba(255, 255, 255, 0.56);
  font-size: 2.3rem;
  font-weight: 400;
  line-height: 115%;
  font-family: "Secondary";
}
.group-section .group-section-text .group-section-heading {
  margin-bottom: 1.5rem;
  color: #fff;
  text-transform: capitalize;
  font-size: 4.25rem;
  font-weight: 700;
  line-height: 108.591%;
  font-family: "Primary";
}
.group-section .group-section-text .group-section-heading span {
  color: #e25858;
}
.product-section-main-div {
  width: 100%;
  box-sizing: border-box;
  padding-inline: 7.5rem;
  margin-top: 4.69rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.product-section-main-div .empty-placeholder {
  width: 45%;
  height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  background: #552d3a;
}

.empty-div-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 1.625rem;
  object-position: center;
}
.product-section-main-div .product-main-content {
  width: calc(55% - 5rem);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.product-section-main-div .product-main-content .sub-heading {
  color: #fff;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 108.591%;
  letter-spacing: 0.2px;
  font-family: "Secondary";
}

.product-section-main-div .product-main-content .sub-heading span {
  color: #e25858;
}

.product-section-main-div .product-main-content .main-heading {
  margin-bottom: 0.88rem;
  color: #fff;
  text-align: left;
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;
  font-family: "Primary";
}

.product-section-main-div .product-main-content .main-heading span {
  color: #e25858;
}

.product-section-main-div .product-main-content .text-paragraph {
  max-width: 35rem;
  margin-bottom: 3rem;
  color: rgba(255, 255, 255, 0.46);
  text-align: left;
  font-family: "Helvetica Now Display";
  font-size: 1.6875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 135%;
}
.product-circuit-container {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.product-circuit-background-blue {
  width: 80%;
  height: 33rem;
  background: linear-gradient(to right, #422c58, #2f253b);
  border: 2px solid #7b49b0;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.product-circuit-background-red {
  width: 80%;
  height: 33rem;
  background: linear-gradient(to right, #392525, #55314c);
  border: 2px solid #e35958;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.product-circuit-background-red img {
  opacity: 0.2;
}
.product-circuit-background-blue img {
  opacity: 0.2;
}
.product-circuit-text {
  position: absolute;
  top: 0;
  font-family: "Helvetica Now Display";
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 3rem;
  margin-left: 5rem;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.8);
}
.product-circuit-text .heading {
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-align: left;
  font-family: "Primary";
}
.product-circuit-text .paragraph {
  margin-top: 1rem;
  font-size: 1.8rem;
  text-align: left;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: 1.8px;
  font-family: "Secondary";
}
.product-circuit-text .blue-button {
  margin-top: 3rem;
  min-width: 16rem;
  margin-bottom: 2rem;
  border: 2px solid #8d33ed;
  border-radius: 12px;
  padding: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
  font-family: "Secondary";
}
.product-circuit-text .red-button {
  margin-top: 3rem;
  min-width: 16rem;
  margin-bottom: 2rem;
  border: 2px solid #e35958;
  border-radius: 12px;
  padding: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
  font-family: "Secondary";
}
.product-circuit-text .blue-button:hover {
  background-color: #7b49b0;
  cursor: pointer;
}
.product-circuit-text .red-button:hover {
  background-color: #e35958;
  cursor: pointer;
}
.parent-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin-top: 4rem;
  flex-wrap: wrap;
}
.parent-container .container {
  width: 80%;
  display: flex;
  align-items: center;
  justify-items: center;
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 4rem;
}
.container .red-container {
  width: 40%;
  height: 30rem;
  background: linear-gradient(to right, #392525, #55314c);
  border: 2px solid #e35958;
  border-radius: 20px;
  padding: 2rem;
  font-family: "Secondary";
}
.container .blue-container {
  width: 50%;
  height: 30rem;
  background: linear-gradient(to right, #422c58, #2f253b);
  border: 2px solid #7b49b0;
  border-radius: 20px;
  padding: 2rem 4rem;
  font-family: "Secondary";
}

.red-container .heading {
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-align: left;
  font-family: "Primary";
}
.blue-container .heading {
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-align: left;
  font-family: "Primary";
}
.red-container .paragraph {
  margin-top: 1rem;
  font-size: 1.5rem;
  text-align: left;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: 1.8px;
  font-family: "Accent";
}
.blue-container .paragraph {
  margin-top: 1rem;
  font-size: 1.5rem;
  text-align: left;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: 1.8px;
  font-family: "Accent";
}
.blue-container .blue-button {
  margin-top: 3rem;
  min-width: 16rem;
  margin-bottom: 2rem;
  border: 2px solid #8d33ed;
  border-radius: 12px;
  padding: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
  font-family: "Secondary";
}
.red-container .red-button {
  margin-top: 3rem;
  min-width: 16rem;
  margin-bottom: 2rem;
  border: 2px solid #e35958;
  border-radius: 12px;
  padding: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
  font-family: "Secondary";
}

.blue-container .blue-button:hover {
  background-color: #7b49b0;
  cursor: pointer;
}
.red-container .red-button:hover {
  background-color: #e35958;
  cursor: pointer;
}

.red-container,
.blue-container {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 1rem;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out forwards infinite;
}

/* Adjust delay for staggered effect */
.fade-in:nth-child(1) {
  animation-delay: 0.2s;
}
.fade-in:nth-child(2) {
  animation-delay: 0.4s;
}
.fade-in:nth-child(3) {
  animation-delay: 0.6s;
}
.fade-in:nth-child(4) {
  animation-delay: 0.8s;
}
.fade-in:nth-child(5) {
  animation-delay: 1s;
}
@media (max-width: 768px) {
  .group-section .group-section-text .text-paragraph {
    font-size: 1.5rem;
    max-width: 20rem;
  }
  .group-section {
    margin-bottom: 28rem;
    margin-top: -2rem;
  }
  .product-circuit-text .red-button,
  .product-circuit-text .blue-button {
    min-width: 10rem;
    font-size: 1.2rem;
  }
  .parent-container .container {
    width: 100%;
    flex-wrap: wrap;

    justify-content: center;
  }

  .container .red-container {
    width: 40%;
    height: 30rem;
    background: linear-gradient(to right, #392525, #55314c);
    border: 2px solid #e35958;
    border-radius: 20px;
    padding: 2rem;
    font-family: "Secondary";
  }

  .red-container .heading,
  .blue-container .heading {
    font-size: 2rem;
  }

  .red-container .paragraph,
  .blue-container .paragraph {
    font-size: 1.2rem;
    letter-spacing: 0.8px;
  }

  .blue-container .blue-button,
  .red-container .red-button {
    margin-top: 2rem;
    min-width: 12rem;
    padding: 1rem 1.5rem;
    font-size: 1rem;
  }
  .group-section img {
    margin-top: 4rem;
    width: 100%;
    height: 20rem;
  }
  .group-section .group-section-text .group-section-heading {
    font-size: 3.25rem;
  }
  .product-circuit-background-blue,
  .product-circuit-background-red {
    width: 98%;
    height: 32rem;
  }
  .product-circuit-text .heading {
    font-size: 2rem;
  }
  .product-circuit-text .paragraph {
    font-size: 1.2rem;
  }
  .red-container .heading,
  .blue-container .heading {
    font-size: 2rem;
  }
  .product-circuit-text {
    margin: 2rem;
  }

  .parent-container .container {
    flex-direction: column;
    gap: 2rem;
  }

  .container .red-container,
  .container .blue-container {
    width: 90%;
    height: auto;
    padding: 1rem;
  }
}
