.career {
  display: flex;
  width: 80%;
  margin: auto;
  height: 100vh;
}
.join-team {
  width: "50%";
  align-self: center;
}
.join-team h2 {
  color: #ffff;
  text-align: left;
  font-size: 50px;
}
.join-team h4 {
  color: #ffff;
  text-align: left;
  font-size: 20px;
}
.career img {
  width: 50%;
  align-self: center;
}
.join-team button {
  display: flex;
  border: none;
  background-color: #df4949;
  padding: 10px 20px;
  color: #ffff;
  font-size: 18px;
  gap: 10px;
  border-radius: 29px;
}
.jobs {
  margin: 40px auto;
  width: 80%;
  background: rgba(247, 249, 252, 1);

  border-radius: 20px;
}
.container {
  padding: 20px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.categories {
  width: 20%;
  padding: 20px;
}
.categories h6 {
  text-align: left;
  margin-block-start: 0em;
  margin-block-end: 0em;
}
.categories button {
  border: none;
  background: none;
  text-align: left;
  padding: 20px 40px 20px 40px;
  width: 100%;
  font-family: Helvetica;
  font-size: 16px;
  display: flex;
  color: #253a67;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
}
.categories .active {
  background-color: #ffffff;
  border-left: 4px solid #0040d8;
  color: #000000;
}
.row .text {
  color: #53648b;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  padding-top: 30px;
  text-align: left !important;
}
.col-md-8 {
  width: 66.67%;
  padding: 20px;
}
.share-button {
  margin-top: 30px;
  width: 100%;
  cursor: pointer;
  border: 2px solid #000000;
  padding: 20px 0px 20px 0px;
  border-radius: 40px;
}
.share-button:hover {
  background-color: #000000;
  color: #fff;
}
.job-listings {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.job-listing {
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.job-listing h2 {
  font-family: Helvetica;
  font-size: 28px;
  font-weight: 700;
  line-height: 44.8px;
  text-align: left;
  margin: 0;
}
.job-listing p {
  display: flex;
  gap: 8px;
}
.location {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.1px;
  border: 1px solid #000000;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border-radius: 29px;
}
.job-listing .description {
  text-align: left;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.type {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.1px;
  border: 1px solid #000000;
  padding: 8px 16px 8px 16px;
  gap: 8px;
  border-radius: 29px;
}

.apply-now {
  background-color: #000000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  position: relative;
  margin-left: auto;
  margin-right: 0px;
  border-radius: 24px;
}
.apply-now p2 {
  text-decoration: none;
  color: #fff;
}
.apply-now:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000000;
}
.apply-now:hover p2 {
  color: #000;
}
.show-more {
  color: #000;
  background: none;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  gap: 10px;
  position: relative;
  border: 1px solid #000000;
  border-radius: 24px;
}

.show-more:hover {
  background-color: #000000;
  color: #fff;
}
@media (max-width: 768px) {
  .career {
    flex-direction: column-reverse;
    width: 95%;
    margin-top: 10rem;
    height: auto;
  }
  .career img {
    width: 90%;
  }
  .join-team h2 {
    color: #ffff;
    text-align: center;
    font-size: 30px;
  }
  .join-team h4 {
    color: #ffff;
    text-align: center;
    font-size: 18px;
  }
  .join-team button {
    display: block;
    margin: auto;
  }
  .jobs {
    width: 100%;
  }
  .container h1 {
    font-size: 30px;
  }
  .row {
    display: block;
  }
  .categories {
    width: auto;
  }
  .col-md-8 {
    width: auto;
    padding: 0px;
  }
}
