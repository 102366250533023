.aboutus-header {
  margin-top: 14rem;
  position: relative;
}
.img1,
.bg1,
.img2,
.bg2,
.img3,
.bg3,
.img4,
.img5,
.bg4,
.bg5 {
  position: absolute;
}
.img1,
.img2,
.img3,
.img4,
.img5 {
  z-index: 2;
}
.bg1,
.bg2,
.bg3,
.bg4,
.bg5 {
  z-index: 1;
}

.img1 {
  top: 56%;
  left: 25%;
}
.bg1 {
  left: 20%;
  top: 30%;
}
.img2 {
  top: 60%;
  right: 25%;
}
.bg2 {
  right: 19%;
  top: 30%;
}
.img3 {
  right: 16%;
  top: 33%;
}
.bg3 {
  right: 10%;
}
.img4 {
  right: 31.5%;
  top: 8%;
}
.bg4 {
  top: -65px;
  right: 25%;
}
.img5 {
  left: 31.5%;
  top: 8%;
}
.bg5 {
  top: -65px;
  left: 25%;
}

.header-svg {
  position: absolute;
  top: -15rem;
  left: 40%;
}
.aboutus-header::before {
  position: absolute;
  content: '';
  width: 61.125rem;
  height: 61.125rem;
  border-radius: 61.125rem;
  background: rgba(226, 88, 88, 0.25);
  filter: blur(231.89999389648438px);
  left: 25%;
  top: -35rem;
  z-index: -1;
}

.aboutUs-main-Container {
  margin-top: 6rem;
  position: relative;
}
.line {
  /* position: absolute; */
  margin: 0 auto;
}
.circleSvg1 {
  position: absolute;
  top: 9%;
  left: 49.2%;
}
.circleSvg2 {
  position: absolute;
  top: 40%;
  left: 49.2%;
}
.circleSvg3 {
  position: absolute;
  top: 60%;
  left: 49%;
}
.aboutUs-main-Container {
  position: relative;
}
.aboutUs-main-Container::before {
  position: absolute;
  content: '';
  width: 61.125rem;
  height: 61.125rem;
  border-radius: 61.125rem;
  background: rgba(226, 88, 88, 0.25);

  filter: blur(231.89999389648438px);
  left: -250px;
  top: 25%;
}
.aboutUs-main-Container::after {
  position: absolute;
  content: '';
  width: 61.125rem;
  height: 61.125rem;
  border-radius: 61.125rem;
  background: rgba(226, 88, 88, 0.25);

  filter: blur(231.89999389648438px);
  right: -30rem;
  top: 70%;
}

.aboutus-part-one,
.aboutus-part-two,
.aboutus-part-three {
  position: absolute;
  /* margin-top: 2rem; */
  display: flex;
  width: 30%;
  flex-direction: column;
  /* text-align: right; */
  justify-content: center;
  /* margin-left: 18% ; */
  /* left: 17%; */
  /* top: 5%; */
  color: rgba(255, 255, 255, 0.8);
  z-index: 1;

  text-align: right;
  font-family: 'Accent';
  font-style: normal;
}
.aboutus-part-one {
  position: absolute;
  /* margin-top: 2rem; */
  display: flex;
  width: 30%;
  flex-direction: column;
  text-align: right;
  justify-content: center;
  align-items: flex-end;
  /* margin-left: 18% ; */
  left: 17%;
  top: 7%;
  color: rgba(255, 255, 255, 0.8);
  z-index: 1;

  text-align: right;
  font-family: 'Accent';
  font-style: normal;
}
.aboutus-part-one h2,
.aboutus-part-two h2,
.aboutus-part-three h2 {
  font-size: 1.8rem;
  font-weight: 700;
  font-family: 'Secondary';
}
.aboutus-part-one p,
.aboutus-part-two p,
.aboutus-part-three p {
  font-size: 1.2rem;
  font-weight: 400;
}

.aboutus-part-two {
  top: 38.5%;
  right: 18%;
  text-align: left;
}
.aboutus-part-three {
  top: 58.5%;
  left: 18%;
  text-align: right;
}
.aboutus-calender {
  border-radius: 0.48213rem;
  background: #e25858;
  /* background: rgba(28, 28, 28, 0.52); */
  color: white;
  font-size: 1rem;
  margin-top: 2rem !important;
  margin-left: 55%;
  padding: 1rem 2rem;
  width: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

/* OUR TEAM */
.ourTeam-section {
  display: flex;
  justify-content: space-evenly;
  /* gap: 2rem; */
  align-items: center;
  position: relative;
}
.ourteam-card {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  /* padding-inline: 2rem; */
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.ourteam-card img {
  width: 12rem;
  height: auto;
}
.ourTeam-section::before {
  position: absolute;
  content: '';
  width: 61.125rem;
  height: 61.125rem;
  border-radius: 61.125rem;
  background: rgba(226, 88, 88, 0.25);

  filter: blur(231.89999389648438px);
  left: -30rem;
  top: 70%;
}
.card-name {
  color: #fff;
  font-family: 'Secondary';
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 122.722%; /* 2.60781rem */
  padding-top: 1rem;
}

.card-rank {
  color: #e25858;
  font-family: 'Secondary';
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 122.722%; /* 2.30106rem */
}
.card-role {
  color: #9f63df;
  font-family: 'Secondary';
  font-size: 1.675rem;
  font-style: normal;
  font-weight: 500;
  line-height: 122.722%; /* 2.30106rem */
}

@media (max-width: 967px) {
  .img1,
  .bg1,
  .img2,
  .bg2,
  .img3,
  .bg3,
  .img4,
  .img5,
  .bg4,
  .bg5,
  .img1,
  .img2,
  .img3,
  .img4,
  .img5,
  .bg1,
  .bg2,
  .bg3,
  .bg4,
  .bg5 {
    display: none; /* Hide images on smaller screens */
  }
}

@media (max-width: 567px) {
  .aboutus-part-one h2,
  .aboutus-part-two h2,
  .aboutus-part-three h2 {
    font-size: 1.4rem;
  }
  .aboutus-part-one p,
  .aboutus-part-two p,
  .aboutus-part-three p {
    font-size: 0.9rem;
    margin-right: 0rem;
  }
  .aboutUs-main-Container {
    margin-top: -1rem;
    margin-bottom: -10rem;
  }
  .aboutus-part-one,
  .aboutus-part-two,
  .aboutus-part-three {
    width: 40%;
  }
  .aboutus-part-one,
  .aboutus-part-three {
    margin-left: -2.4rem;
  }
  .aboutus-part-two {
    margin-right: -2.4rem;
  }
  .ourTeam-section {
    flex-direction: column;
  }
  .ourteam-card {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .contact-main-header {
    width: 100%;
  }
  .circleSvg1,
  .circleSvg2,
  .circleSvg3 {
    display: none;
  }
  .aboutus-calender {
    font-size: 0.8rem;
    margin-top: 1rem !important;
    margin-left: 55%;
    padding: 0.6rem 1rem;
  }
}
