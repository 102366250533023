.hero-home-main-div {
  width: 100%;
  padding: 5rem 0rem 0 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  /* box-sizing: border-box; */
  position: relative;
  /* padding-bottom: 150px; */

  margin-bottom: 14.37rem;
}

.hero-home-main-div .purple-blur {
  width: 100%;

  position: absolute;
  bottom: 10rem;
  left: 0;
  z-index: 1;
}

.hero-home-main-div .black-blur {
  width: 100%;
  max-height: 120vh;
  position: absolute;
  bottom: -150px;
  left: 0;
  z-index: 2;
}

.hero-home-main-div .main-content {
  box-sizing: border-box;
  width: 100%;
  max-width: 55rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 10;
}
.banner-container {
  padding: 10rem 0rem 0rem 0rem;
  display: flex;
  cursor: pointer;
}
.google-play-button {
  position: absolute;
  margin-top: -5rem;
  cursor: pointer;
}
.custom-style {
  display: flex;
  gap: 30px;
  border: 2px solid #000;
  padding: 10px;
  border-radius: 10px;
}
.button-text-container {
  align-self: center;
}
.button-text-container .button-title {
  display: block;
  font-size: small;
}
.button-text-container .button-store-name {
  font-weight: 700;
  font-size: medium;
}
.banner {
  min-width: 100%;
  max-height: 45vh;
}
.banner-dots {
  position: relative;
  font-size: xx-large;
  top: -2.5rem;
  display: flex;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}
.banner-dots .active-dot {
  color: grey;
}
.banner-dots .dot {
  color: black;
}

.hero-home-main-div .main-content .main-heading {
  margin: 0;
  margin-bottom: 1.37rem;
  color: #f9f5ff;
  font-family: "Primary";
  text-align: center;
  font-size: 4.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;
}

.hero-home-main-div .main-content .main-heading span {
  background: linear-gradient(90deg, #9b58e2 12.91%, #9b58e2 48.83%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 5.03713rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;
  font-family: "Primary";
}

.hero-home-main-div .main-content .sub-text {
  margin: 0;
  margin-bottom: 2.13rem;
  color: rgba(255, 255, 255, 0.56);
  font-family: "Accent";
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;
}

.hero-home-main-div .main-content .input-main-div {
  width: 100%;
  height: 5.8125rem;
  margin-bottom: 1.11rem;
  box-sizing: border-box;

  padding-inline: 5rem;
}

.hero-home-main-div .main-content .input-main-div .input-main-container {
  width: 100%;
  height: 100%;
  max-height: 6rem;
  box-sizing: border-box;
  padding: 1rem;

  border-radius: 1.25rem;
  border: 2.241px solid #e25858;
  background: #1b1b1b;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.hero-home-main-div
  .main-content
  .input-main-div
  .input-main-container
  .search-input-box {
  width: calc(100% - 13rem);
  height: 100%;
  border: none;
  background-color: transparent;

  box-sizing: border-box;
  padding-inline: 1rem;

  color: rgba(255, 255, 255, 0.56);
  font-family: "Accent";
  font-size: 1.68075rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;
}

.main-content .input-main-div .input-main-container .search-input-box:focus {
  outline: none;
}

.hero-home-main-div
  .main-content
  .input-main-div
  .input-main-container
  .generate-btn {
  border: none;
  cursor: pointer;
  width: 12rem;
  height: 4rem;
  font-family: "Accent";
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.65rem;

  border-radius: 0.875rem;
  background-color: #e25858;

  color: #fff;

  text-align: center;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;
}

.reponse-container {
  width: 45rem;
  height: 4rem;
  font-family: "Accent";
  letter-spacing: 1px;
  padding: 0.5rem;
  overflow: auto;
  margin-bottom: 2rem;
  color: #cbc2c2;

  border: 2px solid #e25858;
  border-radius: 1rem;
}

.response-container-notrecive {
  display: none;
}

/* For WebKit Browsers (Chrome, Safari) */
.reponse-container::-webkit-scrollbar {
  width: 12px;
}

.reponse-container::-webkit-scrollbar-thumb {
  background-color: #e25858;
  border-radius: 6px;
  border: 1px solid #ffffff;
}

.hero-home-main-div .main-content .suggested-prompts-div {
  width: 100%;
  margin-bottom: 5.22rem;
  box-sizing: border-box;

  /* padding-inline: 4rem; */
  flex-direction: column;
  font-family: "Accent";
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.hero-home-main-div .main-content .suggested-prompts-div .prompts-title {
  color: rgba(255, 255, 255, 0.7);
  font-family: "Accent";
  text-align: center;
  font-size: 1.325rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;
}

.hero-home-main-div .main-content .suggested-prompts-div .prompts-container {
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  row-gap: 1rem;
}

.hero-home-main-div
  .main-content
  .suggested-prompts-div
  .prompts-container
  .prompt-main {
  padding: 0.1rem 1.6rem;
  border-radius: 1.78256rem;
  border: 1px solid #1b1b1b;
  cursor: pointer;
  background: rgba(27, 27, 27, 0.35);
}

.hero-home-main-div
  .main-content
  .suggested-prompts-div
  .prompts-container
  .prompt-main
  .prompt-text {
  margin: 0;
  color: rgba(255, 255, 255, 0.6);
  text-wrap: nowrap;
  font-family: "Accent";
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 131%;
}

.hero-home-main-div .main-content .base-paragraph-div {
  width: 100%;
  box-sizing: border-box;
  padding-inline: 7.5rem;
  font-family: "Accent";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.hero-home-main-div .main-content .base-paragraph-div .sub-heading {
  color: #f9f5ff;
  margin-bottom: 0.44rem;

  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;
}

.hero-home-main-div .main-content .base-paragraph-div .para-heading {
  color: #e25858;
  margin-bottom: 1.19rem;
  font-family: "Secondary";
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;
}
.hero-home-main-div .main-content .base-paragraph-div .para-heading span {
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-family: "Helvetica Now Display";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%; /* 30.406px */
}

.hero-home-main-div .main-content .base-paragraph-div .main-para-text {
  color: rgba(255, 255, 255, 0.8);
  font-family: "Accent";
  text-align: center;
  font-size: 1.225rem;
  font-style: normal;
  font-weight: 400;
  line-height: 131%;
}

@media screen and (max-width: 600px) {
  .hero-home-main-div {
    padding-top: 0rem;
  }
  .banner {
    height: 18vh;
  }
  .banner-dots {
    font-size: x-large;
    top: -1.5rem;
  }
  .google-play-button {
    position: absolute;
    margin-top: -2.2rem;
    cursor: pointer;
  }
  .custom-style {
    display: flex;
    gap: 10px;

    border: 1px solid #000;
    padding: 0px 5px 0px 5px;
    border-radius: 10px;
  }
  .button-text-container {
    align-self: center;
  }
  .button-text-container .button-title {
    display: block;
    font-size: xx-small;
  }
  .button-text-container .button-store-name {
    font-weight: 700;
    font-size: x-small;
  }
  .banner-container {
    padding: 0rem 0rem 0rem 0rem;
  }
  .hero-home-main-div .main-content .main-heading {
    font-size: 2.2rem;
  }
  .hero-home-main-div .main-content .main-heading span {
    font-size: 2.3rem;
  }
  .hero-home-main-div .main-content .sub-text {
    font-size: 1rem;
  }
  .hero-home-main-div .main-content .input-main-div {
    padding-inline: 1rem;
  }
  .hero-home-main-div
    .main-content
    .input-main-div
    .input-main-container
    .generate-btn {
    width: 7rem;
    font-size: 0.85rem;
    height: 2.5rem;
    padding: 1rem;
  }
  .hero-home-main-div
    .main-content
    .input-main-div
    .input-main-container
    .search-input-box {
    width: auto;
    padding: 0;
    font-size: 0.85rem;
  }
  .hero-home-main-div .main-content .input-main-div {
    height: auto;
  }
  .hero-home-main-div
    .main-content
    .suggested-prompts-div
    .prompts-container
    .prompt-main
    .prompt-text {
    font-size: 1rem;
  }
  .hero-home-main-div .main-content .base-paragraph-div {
    padding-inline: 1rem;
  }
  .hero-home-main-div .main-content .base-paragraph-div .sub-heading {
    font-size: 1.25rem;
  }
  .hero-home-main-div .main-content .base-paragraph-div .para-heading {
    font-size: 1.5rem;
  }
  .hero-home-main-div .main-content .base-paragraph-div .main-para-text {
    font-size: 0.997rem;
  }
  .our-numbers-main-div {
    padding-inline: 1rem !important;
  }
  .our-numbers-main-div .numbers-main-content .top-numbers {
    height: 8.5rem;
  }
  .our-numbers-main-div
    .numbers-main-content
    .top-numbers
    .numbers-div
    .main-numbers
    .num-value {
    font-size: 2rem !important;
  }
  .our-numbers-main-div
    .numbers-main-content
    .top-numbers
    .numbers-div
    .main-numbers
    .num-label {
    font-size: 1rem !important;
  }
  .our-numbers-main-div .numbers-main-content .bottom-numbers,
  .additional-section-main-div {
    flex-direction: column;
  }
  .our-numbers-main-div .numbers-main-content .bottom-numbers .briefing-div {
    padding-top: 1.5rem !important;
  }
  .our-numbers-main-div
    .numbers-main-content
    .bottom-numbers
    .briefing-div
    .brief
    .brief-icon {
    width: 3rem !important;
  }
  .our-numbers-main-div
    .numbers-main-content
    .bottom-numbers
    .briefing-div
    .brief
    .brief-label {
    font-size: 1.5rem !important;
  }
  .our-numbers-main-div
    .numbers-main-content
    .bottom-numbers
    .briefing-div
    .brief
    .brief-para {
    font-size: 1rem !important;
  }
  .minsky-container {
    padding-inline: 1rem !important;
    padding-top: 2rem;
  }
  .minsky-container .heading {
    font-size: 2rem !important;
    text-align: center;
  }
  .minsky-container .heading span {
    font-size: 2rem !important;
  }
  .minsky-container .row-container {
    flex-direction: column;
    gap: 0.5rem !important;
  }
  .minsky-container .row-container .stats-div .sub-heading {
    font-size: 1.25rem !important;
    text-align: center;
  }
  .minsky-container .row-container .stats-div,
  .additional-section-main-div .additional-main-content {
    width: auto !important;
  }
  .minsky-container .row-container .stats-div .card-icon {
    width: 3.125rem !important;
  }
  .minsky-container
    .row-container
    .stats-div
    .minsky-card-hover
    .text-div
    .card-title {
    font-size: 1.3rem !important;
  }
  .minsky-container
    .row-container
    .stats-div
    .minsky-card-hover
    .text-div
    .card-para {
    font-size: 1rem !important;
  }
  .minsky-container
    .row-container
    .stats-div
    .minsky-card
    .text-div
    .card-title {
    font-size: 1.3rem !important;
  }
  .minsky-container .row-container .right-empty-div,
  .additional-section-main-div .empty-placeholder {
    width: 100% !important;
    height: 20rem !important;
    min-height: 20rem !important;
  }
  .benefits-main-div .main-title .main-heading {
    font-size: 2rem !important ;
  }
  .benefits-main-div .main-title .main-heading span,
  .unique-section-main-div .header-main .main-heading {
    font-size: 2rem !important;
  }
  .benefits-main-div .main-title img {
    width: 10rem;
  }
  .benefits-main-div {
    margin-bottom: 5rem !important;
    padding-bottom: 2rem !important;
  }
  .benefits-main-div .sub-title {
    font-size: 1rem !important;
    margin-bottom: 0 !important;
  }
  .benefits-main-div .card-main-container .benefits-card-main img {
    width: 3rem;
  }
  .additional-section-main-div .additional-main-content {
    align-items: center !important;
    /* text-align: center !important; */
  }
  .additional-section-main-div .additional-main-content .main-heading {
    font-size: 2rem !important;
  }
  .benefits-main-div .card-main-container .benefits-card-main .card-title {
    font-size: 1.25rem !important;
  }
  .benefits-main-div .card-main-container .benefits-card-main .card-para,
  .schedule-demo-main-div .schedule-main-content .main-text,
  .additional-section-main-div .additional-main-content .text-paragraph,
  .unique-section-main-div .header-main .text-paragraph {
    font-size: 1rem !important;
    text-align: center !important;
  }
  .benefits-main-div .card-main-container .centre-logo-div {
    width: 12rem;
  }
  .discover-cards-main-div {
    display: none !important;
  }
  .schedule-demo-main-div .schedule-main-content .main-title,
  .schedule-demo-main-div .schedule-main-content .main-title span {
    font-size: 2rem !important;
    width: 80%;
  }
  .additional-section-main-div .additional-main-content .main-heading {
    text-align: center !important;
  }
  .additional-section-main-div,
  .unique-section-main-div .header-main {
    padding-inline: 1rem !important;
  }
  .unique-section-main-div,
  .additional-section-main-div {
    margin-top: 6rem !important;
  }
  .additional-section-main-div .empty-placeholder {
    width: 100% !important;
  }
  .unique-section-main-div .unique-card-container {
    padding-inline: 1rem !important;
    gap: 2rem !important;
    /* flex-direction: column; */
  }
  .unique-section-main-div .unique-card-container .unique-card {
    width: 100%;
  }
  .unique-section-main-div .unique-card-container .unique-card .heading-div {
    flex-direction: column !important;
    /* font-size: 1rem !important; */
  }
  .unique-section-main-div
    .unique-card-container
    .unique-card
    .heading-div
    .main-card-heading {
    font-size: 1rem !important;
  }
  .unique-section-main-div .unique-card-container .unique-card .main-card-text {
    font-size: 1.2rem !important;
    text-align: center !important;
  }
  .unique-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    padding: 0%;
  }
  .footer-main-div {
    padding-inline: 1rem !important;
    margin-top: 4rem !important;
    height: auto !important;
    justify-content: center !important;
    /* font-size: 1rem !important; */
  }
  .footer-main-div .footer-main-content .footer-main-para {
    font-size: 1rem !important;
    margin-bottom: 8.8rem;
  }
  .main-logo-div .logo-text {
    font-size: 1rem;
  }
  .footer-main-div .footer-buttons .footer-list-title {
    font-size: 1rem !important;
  }
  .footer-main-div .footer-buttons .footer-columns .footer-links {
    font-size: 0.85rem !important;
  }
  .footer-main-div .footer-buttons {
    padding-top: 5.5rem;
    flex-wrap: wrap;
    gap: 1rem !important;
  }
  .footer-main-div .footer-main-content {
    margin-bottom: 6rem;
  }

  /* .newsletter-btn {
    visibility: hidden;
    display: none;
  } */
  .footer-main-div .bottom-bar-container {
    padding-inline: 1rem !important;
  }
  .bottom-bar-container .bottom-bar-text {
    font-size: 0.85rem !important;
    height: 4rem !important;
  }
}
