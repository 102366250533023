.contact-main {
  margin-top: 15rem;
  color: #fff;
}
.contact-main-header {
  width: 60%;
  height: auto;
  margin: 0 auto;
  position: relative;
}
.contact-main-header::after {
  position: absolute;
  content: '';
  right: -40rem;
  top: -20rem;
  border-radius: 57.8125rem;
  background: rgba(226, 88, 88, 0.5);

  filter: blur(151.85000610351562px);
  height: 57rem;
  width: 57rem;
  z-index: -1;
}
.contact-main-header::before {
  position: absolute;
  content: '';
  right: -40rem;
  top: -20rem;
  border-radius: 45.875rem;
  background: #1b1b1b;

  filter: blur(151.85000610351562px);
  width: 45.875rem;
  height: 45.875rem;
  z-index: 0;
}
.grp1,
.grp2,
.grp3,
.grp4,
.grp5 {
  position: absolute;
}
.grp1 {
  width: 16rem;
  left: 5%;
  top: 7rem;
}
.grp2 {
  width: 12rem;
  left: -5%;
  top: 3rem;
}
.grp3 {
  width: 11rem;
  right: 0%;
  top: 3rem;
}
.grp4 {
  width: 16rem;
  right: 5%;
  top: 7rem;
}

.grp5 {
  width: 9rem;
  left: 5%;
  top: 0.4rem;
}

.contact-main-header h2 {
  color: #fff;
  text-align: center;
  font-family: 'Primary';
  font-size: 3.90356rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0%; /* 0rem */
}
.contact-main-header p,
.contact-info span p {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-family: 'Accent';
  /* font-size: 1.59575rem; */
  font-style: normal;
  font-weight: 400;
  line-height: 131%; /* 2.09044rem */
}

.contact-main-header p {
  font-size: 1.1rem;
  width: 100%;
  margin: 0 auto;
  font-family: 'Accent';
}

.contact-TTS {
  border-radius: 1rem;
  border: 2.207px solid #9b58e2;
  background: rgba(28, 28, 28, 0.52);
  color: white;
  font-size: 1.6rem;
  margin: 2rem auto !important;
  padding: 1rem 1.5rem;
  width: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-family: 'Accent';
}

.contact-main-body {
  display: flex;
  margin-top: 10rem;
  justify-content: space-around;
  /* align-items: center; */
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.contact-info span,
.contact-info span p {
  text-align: left;
  font-size: 1.2rem;
  font-family: 'Accent';
  margin-top: 0.3rem;
}

.contact-info h2 {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 1rem;
  font-family: 'Accent';
  font-size: 1.5rem;
}
.contact-socials {
  display: flex;
  gap: 3rem;
  margin-top: 1rem;
}

.contact-socials svg {
  height: 3rem;
  width: 3rem;
}
.contact-socials svg:hover {
  cursor: pointer;
}

/* Contact Form */
.form-main {
  width: 25rem;
  height: 40rem;

  position: relative;
  padding: 1rem 3rem 1rem 3rem;
  border-radius: 1.875rem;
  text-align: left;
}
.form-main::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to top,
    rgba(226, 88, 88, 0.3),
    rgba(155, 88, 226, 0.5)
  );
  /* border-radius: 21.875rem; */
  filter: blur(133.0500030517578px); /* Adjust the blur value as needed */
  z-index: -1; /* Move the pseudo-element below the content */
}
.form-main::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1b1b1b;
  border-radius: 1.875rem;

  z-index: -1;
  opacity: 0.2; /* Move the pseudo-element below the content */
}
/* .form{
        position: absolute;
        z-index: 10;
    } */
.form-input {
  padding-bottom: 1rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1;
}

.form input {
  width: 90%;
  padding: 1rem;
  border-radius: 0.75rem;
  border: 1px solid #9b58e2;
  font-family: 'Accent';
  background: rgba(155, 88, 226, 0.05);
  color: #fff;
}
.form textarea {
  width: 90%;
  padding: 1rem;
  border-radius: 0.75rem;
  border: 1px solid #9b58e2;
  font-family: 'Accent';

  background: rgba(155, 88, 226, 0.05);
  height: 5rem;
  color: #fff;
}

.form-main p {
  font-family: 'Accent';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 122.722%;
  width: 100%;
  padding-bottom: 2rem;
}
.form-main span {
  color: #af68fa;
}
.form-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.form button {
  border-radius: 0.58038rem;
  background: #9b58e2;
  border: none;
  padding: 0.7rem 2rem;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  font-size: 1.4rem;
  font-family: 'Accent';
}

.form button:hover {
  background: #8834e2;
  cursor: pointer;
}
.form label {
  padding-bottom: 1rem;
}
@media (max-width: 967px) {
  .contact-main-header {
    width: 60%; /* Adjust width as needed */
  }
  .grp1,
  .grp2,
  .grp3,
  .grp4,
  .grp5 {
    display: none; /* Hide images on smaller screens */
  }
}

/* For screens smaller than 576px */
@media (max-width: 575px) {
  .contact-main {
    margin-top: 10rem;
  }

  .contact-main-header h2 {
    font-size: 2.5rem;
  }

  .contact-main-header p,
  .contact-info span p {
    font-size: 1rem;
  }

  .contact-TTS {
    font-size: 1.2rem;
  }

  .contact-main-body {
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }

  .form-main {
    width: 90%;
  }

  .form input,
  .form textarea {
    width: 9 0%;
  }

  .form button {
    padding: 0.7rem 1rem;
  }
}
