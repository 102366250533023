.minsky-container {
  width: 100%;
  box-sizing: border-box;
  text-align: start;
  padding: 0rem 5rem 0 5rem;
  border-top: 2px solid #e25858;
  border-bottom: 2px solid #e25858;
  background: rgba(155, 88, 226, 0.06);

  padding-top: 3.5rem;
  padding-bottom: 3.25rem;
  padding-inline: 4.5rem;
}

.minsky-container .heading {
  color: #fff;
  text-wrap: wrap;
  font-family: "Primary";
  font-size: 2.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;
}

.minsky-container .heading span {
  color: #e25858;
  font-family: "Primary";
  font-family: "Helvetica Now Display Bold";
  font-size: 3.875rem;
  font-style: italic;
  /* font-weight: 800; */
  line-height: 108.591%;
}

.minsky-container .row-container {
  width: 100%;
  padding-top: 1.25rem;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  gap: 5.56rem;
}

.minsky-container .row-container .stats-div {
  width: calc(48% - 2.78rem);
}

.minsky-container .row-container .stats-div .sub-heading {
  width: 100%;
  margin-bottom: 3.75rem;
  color: rgba(255, 255, 255, 0.56);

  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #000;
  font-family: "Secondary";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;
}

.minsky-container .row-container .stats-div .minsky-card {
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 2.5rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.3rem;

  padding-left: 2rem;
  padding-right: 4.7rem;
  cursor: pointer;
  /* padding-top: 1.7rem; */
}

.minsky-container .row-container .stats-div .card-icon {
  width: 2.1rem;
  aspect-ratio: 1/1;
  margin: 0rem 1rem;
  opacity: 0.6;
}

.minsky-container .row-container .stats-div .text-div {
  padding-right: 0.1rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.minsky-container .row-container .stats-div .minsky-card .text-div .card-title {
  color: rgba(255, 255, 255, 0.63);

  font-family: "Helvetica Now Display";
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;
}

.text-color {
  color: #fff;
  padding: 1rem;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;
}

.minsky-container .row-container .stats-div .text-div .card-para {
  display: none;
}

.text-color2 h1 {
  display: block;

  color: rgba(255, 255, 255, 0.56);

  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;
}
.text-color2:hover,
.text-color2.active {
  width: 100%;
  box-sizing: border-box;
  padding-left: 1rem;
  padding-top: 0.7rem;
  padding-right: 0.7rem;
  padding-bottom: 0.8rem;

  background: linear-gradient(
    180deg,
    rgba(155, 88, 226, 0.28) 0.29%,
    rgba(155, 88, 226, 0) 107.99%
  );
}
.gapImage2 img {
  width: 2.5rem;
  margin-right: 1rem;
  align-self: center;
}
.text-color2 {
  width: 100%;
  box-sizing: border-box;
  padding-left: 1rem;
  padding-top: 0.7rem;
  padding-right: 0.7rem;
  padding-bottom: 0.8rem;
}

.minsky-container .row-container .right-empty-div {
  width: calc(52% - 2.78rem);
  height: 100%;
  min-height: 35rem;
  border-radius: 1.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #000; */
  background: transparent;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.85); */
}
.right-empty-div-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 1.625rem;
  object-position: center;
}
.unique-section-btn-title {
  display: block;

  color: rgba(255, 255, 255, 0.56);

  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;
}

.unique-section-book-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .right-empty-div-image {
    margin-top: 1rem;
  }
  .minsky-container .row-container .right-empty-div {
    margin-top: -2rem;
  }
}
