.discover-cards-main-div {
  width: 100%;
  margin-bottom: 5.62rem;
  margin-top: -5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.discover-cards-main-div .discover-sub-title {
  margin-left: 4.5rem;
  margin-bottom: 1.81rem;
  font-family: "Secondary";
  color: #fff;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 108.591%;
}

.discover-cards-main-div .discover-sub-title span {
  color: #e25858;
  font-family: "Secondary";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 108.591%;
  letter-spacing: 0.2px;
}

.discover-cards-main-div .discover-title {
  margin-left: 4.5rem;
  height: 2.5rem;
  max-width: 45rem;
  text-align: start;
  font-family: "Primary";
  color: #fff;
  font-size: 3.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 108.591%;
}

.discover-cards-main-div .discover-title span {
  color: #e25858;
  font-size: 4.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 108.591%;
  font-family: "Primary";
}

.discover-cards-main-div .card-grid-image {
  width: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* Updated for 7 columns */
  grid-template-rows: repeat(2, 1fr); /* Updated for 2 rows */
  gap: 2rem;
}

.grid-item {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
  border: 2px solid transparent;
  border-radius: 20px;
  padding: 10px;
  font-size: 18px;
  font-family: "Accent";
  color: aliceblue;
  cursor: pointer;
  position: relative;
  perspective: 1000px;
  transition: all 0.4s ease-in;
  width: 200px;
  height: 250px;
}

.grid-item:nth-child(1) {
  grid-area: 1 / 2 / 2 / 3;
  position: relative;
  top: 10rem;
  border-color: #9b59e3;
  background-color: #9b59e3;
} /* Center between rows in column 1 */
.grid-item:nth-child(2) {
  grid-area: 1 / 3 / 2 / 4;
  border-color: #e35958;
  background-color: #e35958;
} /* Row 1, column 2 */
.grid-item:nth-child(3) {
  grid-area: 2 / 3 / 3 / 4;
  border-color: #e35958;
  background-color: #e35958;
} /* Row 2, column 2 */
.grid-item:nth-child(4) {
  grid-area: 1 / 4 / 2 / 5;
  border-color: #9b59e3;
  background-color: #9b59e3;
} /* Row 1, column 3 */
.grid-item:nth-child(5) {
  grid-area: 2 / 4 / 3 / 5;
  border-color: #9b59e3;
  background-color: #9b59e3;
} /* Row 2, column 3 */
.grid-item:nth-child(6) {
  grid-area: 1 / 5 / 2 / 6;
  border-color: #e35958;
  background-color: #e35958;
} /* Row 1, column 4 */
.grid-item:nth-child(7) {
  grid-area: 2 / 5 / 3 / 6;
  border-color: #e35958;
  background-color: #e35958;
} /* Row 2, column 4 */
.grid-item:nth-child(8) {
  grid-area: 1 / 6 / 2 / 7;
  position: relative;
  top: 10rem;
  border-color: #9b59e3;
  background-color: #9b59e3;
} /* Center between rows in column 5 */

.grid-item:nth-child(1):hover,
.grid-item:nth-child(4):hover,
.grid-item:nth-child(5):hover,
.grid-item:nth-child(8):hover {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.9);
  scale: 105%;
}
.grid-item:nth-child(2):hover,
.grid-item:nth-child(3):hover,
.grid-item:nth-child(6):hover,
.grid-item:nth-child(7):hover {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.9);
  scale: 105%;
}

.grid-item ul li {
  margin: 5px 0;
}

.grid-item:hover ul {
  transform: rotateY(180deg);
}

.grid-item .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  backface-visibility: hidden;
  transition: transform 0.8s ease-in-out;
  border-radius: 20px;
  z-index: 1;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
  font-family: "Secondary";
}

.grid-item:hover .image-overlay {
  transform: rotateY(180deg);
}

.grid-item ul {
  margin: 0px;
  padding: 0px 20px;
  text-align: left;
  width: 90%;
  height: 100%;
  transition: transform 0.8s ease-in-out;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  pointer-events: none;
}

.grid-item:hover ul {
  pointer-events: auto;
  transform: rotateY(0deg);
}
.grid-item .image-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 20px;
  transition: opacity 0.4s ease-in;
  opacity: 1;
}
.grid-item .image-overlay > div {
  position: relative;
  z-index: 2;
}
