.schedule-main-button {
  width: 17.25rem;
  height: 4.8125rem;

  border-radius: 0.6875rem;
  background: #e25858;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  color: #fff;
  font-family: 'Secondary';
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;

  cursor: pointer;
}

.schedule-main-button:hover {
  background: #f37777;
}
