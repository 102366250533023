.additional-section-main-div {
  width: 100%;
  box-sizing: border-box;
  padding-inline: 7.5rem;
  margin-top: 6.69rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5rem;
}
.crousel-testiomonials {
  scroll-behavior: smooth;
  text-wrap: wrap;
  overflow-x: auto; /* Enable horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  scrollbar-width: none; /* Firefox */
}
.crousel-testiomonials::-webkit-scrollbar {
  display: none; /* WebKit browsers */
}

.additional-section-main-div .additional-main-content {
  width: calc(75% - 5rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.additional-section-main-div .additional-main-content .sub-heading {
  color: #fff;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;
  letter-spacing: 0.2px;
}

.additional-section-main-div .additional-main-content .sub-heading span {
  color: #e25858;
}

.additional-section-main-div .additional-main-content .main-heading {
  margin-bottom: 0.88rem;
  color: #fff;
  text-align: center;
  font-size: 4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 108.591%;
}

.additional-section-main-div .additional-main-content .main-heading span {
  color: #e25858;
}

.additional-section-main-div .additional-main-content .text-paragraph {
  max-width: 35rem;
  margin-bottom: 3rem;
  color: rgba(255, 255, 255, 0.46);
  text-align: center;
  font-family: "Helvetica Now Display";
  font-size: 1.6875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 135%;
}
.testimonials {
  flex: 0 0 auto;
  width: 16rem;
  border-radius: 8px;
  background: rgba(155, 88, 226, 0.11);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 2rem;
}

.testimonials .testimonials-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  gap: 1rem;
}
.testimonials .testimonials-header img {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
}
.testimonials .testimonials-text {
  text-align: left;
  color: #a19fa4;
  font-size: 0.9rem;
  padding: 1rem 0;
}
.testimonials .testimonials-audio {
  background: rgba(226, 88, 88, 0.09);
  width: 100%;
  padding: 0.5rem 0.2rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* gap: 1rem; */
}
