.footer-main-div {
  margin-top: 4.25rem;
  width: 100%;
  box-sizing: border-box;
  padding-inline: 9.5rem;
  padding-top: 1.75rem;
  height: 21rem;
  border-top: 2px solid #e25858;

  background: rgba(27, 27, 27, 0.67);

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;

  position: relative;
}

.footer-main-div .footer-main-content {
  width: 30%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.footer-main-div .footer-main-content .logo-container {
  margin-bottom: 2.8rem;
}

.footer-main-div .footer-main-content .footer-main-para {
  margin-bottom: 1.8rem;
  text-align: left;
  color: rgba(255, 255, 255, 0.56);
  font-family: "Accent";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
}

.footer-main-div .footer-main-content .newsletter-btn {
  width: 20.5625rem;
  height: 3.375rem;
  border: none;
  background-color: transparent;
  border-radius: 0.75rem;
  border: 1.2px solid #e25858;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  color: #f9f5ff;
  font-family: "Secondary";
  text-align: center;
  font-size: 1.225rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.footer-main-div .footer-main-content .newsletter-btn:hover {
  background-color: #e25858;
  border: 1.2px solid #000;
}

.footer-main-div .footer-buttons {
  width: 50%;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;

  margin-bottom: 6.5rem;
}

.footer-main-div .footer-buttons .footer-list-title {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  color: #e25858;
  font-family: "Secondary";
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.3px;
}

.footer-main-div .footer-buttons .footer-columns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.7rem;
}

.footer-main-div .footer-buttons .footer-columns .footer-links {
  text-decoration: none;
  text-transform: capitalize;
  color: #fff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "Accent";
}

.footer-main-div .bottom-bar-container {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  box-sizing: border-box;

  padding-inline: 9rem;
}

.bottom-bar-container .bottom-bar-text {
  color: rgba(255, 255, 255, 0.56);
  font-family: "Accent";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;

  width: 100%;
  border-top: 0.6px solid #fff;
  height: 3rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.email-form-input {
  text-align: left;
  margin-top: 0.3rem;
}

.email-form-input input {
  border: 1px solid #9b58e2;
  background: rgba(155, 88, 226, 0.05);
  color: #fff;
  font-size: 1rem;
  font-family: "Accent";
}

.email-form-input input::placeholder {
  color: rgba(255, 255, 255, 0.5); /* Adjust placeholder color as needed */
}

.email-form-input input:focus {
  outline: none;
  border-color: #af68fa; /* Adjust focus border color as needed */
}

.email-form-input input:hover {
  background: rgba(
    155,
    88,
    226,
    0.1
  ); /* Adjust hover background color as needed */
}

.email-form-input label {
  font-family: "Helvetica Now Display";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 122.722%;
  padding-bottom: 0.5rem;
}
.footer-links {
  cursor: pointer;
}
@media (max-width: 468px) {
  .footer-main-div .footer-buttons .footer-columns:last-child {
    flex-direction: row;
  }
  .footer-main-div .footer-buttons .footer-columns:nth-last-child(2) {
    flex-direction: row;
  }
  .footer-main-div .footer-main-content .logo-container {
    margin-bottom: 1.3rem;
  }
  .footer-main-div {
    flex-direction: column;
    gap: 0;
  }
  .footer-main-div .footer-main-content {
    width: 80%;
  }
  .footer-main-div .footer-main-content .footer-main-para {
    margin-bottom: 0 !important;
  }
  .footer-main-div .footer-main-content {
    margin-bottom: 0 !important;
    margin-left: 2rem;
  }
  .footer-main-div .footer-buttons {
    padding-top: 0rem !important;
    width: 60%;
    margin: 0 auto 6.5rem 2rem;
  }
  .newsletter-btn {
    width: auto !important;
    font-size: 13px !important;
    height: auto !important;
    margin-top: 15px;
    margin-bottom: 10px;
    padding: 10px !important;
  }
}
