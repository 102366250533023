.circuit-container {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.circuit-image {
  width: 100%;
}
.video-div {
  padding-left: 5rem;
  padding-right: 5rem;
  margin-top: 5rem;
}
.electric-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(0, 255, 255, 0.8),
    rgba(0, 255, 255, 0)
  );
  mix-blend-mode: screen;
  opacity: 0;
  animation: electric-animation 3s infinite;
}

@keyframes electric-animation {
  0% {
    opacity: 0.5;
    transform: translateX(-100%);
  }
  50% {
    opacity: 0.8;
    transform: translateX(100%);
  }
  100% {
    opacity: 0.5;
    transform: translateX(-100%);
  }
}
@media (max-width: 768px) {
  .circuit-image {
    margin-left: 0rem;
  }
  .video {
    margin-left: 0;
  }
  .video-div {
    margin-top: 1.5rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
