.main-logo-div {
  cursor: pointer;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.69rem;
  text-decoration: none;
}

.main-logo-div .logo {
  width: 10rem;
}
.main-logo-div .logo img {
  width: 200%;
}
.main-logo-div .logo-text {
  color: #fff;
  text-align: center;
  font-family: 'Helvetica Now Display';
  font-size: 1.62869rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
