.navbar-main-container {
  width: 100%;
  height: 8rem;
  box-sizing: border-box;
  padding-inline: 3.5rem;
  padding-top: 1.87rem;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: transparent;
}

.navbar-main-container .blur-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #1b1b1b;
  filter: blur(25.549999237060547px);
  z-index: 0;
}
.main-logo {
  filter: blur(0px);
  align-items: flex-start;
}
.navbar-main-container .navbar-main-items {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 7;
}

.navbar-main-container .navbar-main-items .nav-options {
  max-width: 43.875rem;
  width: 70%;
  height: 3.6875rem;
  box-sizing: border-box;
  padding-inline: 1.75rem;
  border-radius: 2.125rem;
  border: 1px solid #e25858;
  background: rgba(29, 28, 30, 0.01);
  backdrop-filter: blur(3.8499999046325684px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.nav-optionsTry {
  width: 100%;
  max-height: 3.6875rem;
  box-sizing: border-box;
  padding: 1.8rem 2rem;
  border-radius: 2.125rem;
  border: 1px solid #e25858;
  background: rgba(29, 28, 30, 0.01);
  backdrop-filter: blur(3.8499999046325684px);
  color: #fff;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1.25rem;
}

.navbar-main-container .navbar-main-items .nav-options .nav-item {
  color: #fff;
  font-family: "Secondary";
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  text-decoration: none !important;
  text-wrap: nowrap;
}
.nav-btn {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navbar-main-container .navbar-main-items .user-btns {
  display: none;
}

.navbar-main-container .menu-btn {
  display: none;
}

.navbar-main-container .navbar-main-items .user-btns .log-in-btn {
  text-wrap: nowrap;
  border: none;
  background-color: transparent;
  color: #fff;
  font-family: "Secondary";
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.navbar-main-container .navbar-main-items .user-btns .sign-in-btn {
  text-wrap: nowrap;
  border-radius: 2.125rem;
  border: 1px solid #e25858;
  background: rgba(155, 88, 226, 0.1);
  backdrop-filter: blur(5.849999904632568px);
  color: #fff;
  cursor: pointer;
  font-family: "Secondary";
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.user-btns {
  display: none;
}
@media (max-width: 1700px) {
  .navbar-main-container .navbar-main-items .nav-options .nav-item {
    font-size: 1rem;
  }
  .navbar-main-container .navbar-main-items .nav-options {
    max-width: 35.875rem;
    width: 60%;
    height: 3.1875rem;

    gap: 0.8rem;
  }
  .nav-optionsTry {
    height: 3.1875rem;
    padding: 1.5rem 1.7rem;
    gap: 5px;
    font-size: 1rem;
  }
}
@media (max-width: 1500px) {
  .navbar-main-container .navbar-main-items .nav-options .nav-item {
    font-size: 0.8rem;
  }
  .navbar-main-container .navbar-main-items .nav-options {
    max-width: 32.875rem;

    height: 2.875rem;

    gap: 0.9rem;
  }
  .nav-optionsTry {
    height: 2.875rem;
    padding: 1.2rem;
    gap: 5px;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 600px) {
  .main-logo-div .logo {
    width: 14.175rem !important;
  }
  .main-logo-div .logo img {
    width: 100%;
  }
  .navbar-main-container .navbar-main-items .nav-options {
    display: none;
  }
  .main-logo-div .logo-text {
    font-size: 1.2rem !important;
  }
  .navbar-main-container .navbar-main-items .user-btns .menu-btn {
    z-index: 999;
    padding: 0.6rem;
    text-wrap: nowrap;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 1px solid #e25858;
    background: rgba(155, 88, 226, 0.1);
    backdrop-filter: blur(5.849999904632568px);
    color: #fff;
    cursor: pointer;
    font-family: "Secondary";
    text-align: center;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    display: none;
  }
}

@media (max-width: 1400px) {
  .navbar-main-container .navbar-main-items .user-btns .sign-in-btn {
    padding: 0.5rem 1.2rem;
  }
}

@media (max-width: 1150px) {
  .navbar-main-container .navbar-main-items .nav-options {
    display: none;
  }
  .nav-optionsTry {
    display: none;
  }
  .navbar-main-container .navbar-main-items .user-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.31rem;
    margin-left: 1.31rem;
    z-index: 444;
    display: block;
  }
  .navbar-main-container .navbar-main-items .user-btns .log-in-btn,
  .navbar-main-container .navbar-main-items .user-btns .sign-in-btn {
    display: none;
  }

  .navbar-main-container .navbar-main-items .user-btns .menu-btn {
    z-index: 999;
    padding: 0.6rem;
    text-wrap: nowrap;
    aspect-ratio: 1/1;
    font-family: "Secondary";
    border-radius: 50%;
    border: 1px solid #e25858;
    background: rgba(155, 88, 226, 0.1);
    backdrop-filter: blur(5.849999904632568px);

    color: #fff;
    cursor: pointer;

    text-align: center;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    display: flex;
  }
}
