.unique-section-main-div {
  width: 100%;
  margin-top: 7rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.unique-section-main-div .header-main {
  width: 100%;
  max-width: 55rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.unique-section-main-div .header-main .sub-heading {
  color: #e25858;
  margin-bottom: 1.12rem;

  font-size: 1.5rem;
  font-style: normal;
  font-weight: 900;
  line-height: 108.591%;
}

.unique-section-main-div .header-main .main-heading {
  margin-bottom: 1.5rem;
  color: #fff;
  text-transform: capitalize;
  font-family: 'Primary';
  font-size: 4.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;
}

.unique-section-main-div .header-main .main-heading span {
  color: #e25858;
}

.unique-section-main-div .header-main .text-paragraph {
  max-width: 42rem;
  font-family: 'Accent';
  color: rgba(255, 255, 255, 0.56);
  text-align: center;
  font-size: 1.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
}

.unique-section-main-div .unique-card-container {
  padding-top: 5.25rem;
  width: 100%;
  box-sizing: border-box;
  padding-inline: 6rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 6.6rem;
  row-gap: 3rem;
  justify-content: center;
  margin-bottom: 3rem;
}

.unique-section-main-div .unique-card-container .unique-card {
  padding: 1rem 1.2rem;
}

.unique-section-main-div .unique-card-container .unique-card .heading-div {
  margin-bottom: 1.37rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.4rem;
}

.unique-section-main-div
  .unique-card-container
  .unique-card
  .heading-div
  .main-card-heading {
  color: #fff;
  text-align: left;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;
}

.unique-section-main-div .unique-card-container .unique-card .main-card-text {
  text-align: left;
  max-width: 23rem;
  color: rgba(255, 255, 255, 0.56);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  margin-bottom: 2rem;
  font-family: 'Accent';
}
.view-button {
  margin-top: 2rem;
  max-width: 11rem;
  margin-bottom: 2rem;
  border: 1px solid #e25858;
  border-radius: 8px;
  padding: 1rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.view-button:hover {
  background-color: #e25858;
  color: white;
  cursor: pointer;
}
.unique-card:hover {
  background-color: #4b2a39;
  border-radius: 10px;
}
.unique-section-main-div .unique-card:hover .view-button {
  opacity: 1;
}

@media (max-width: 967px) {
  .unique-section-main-div .unique-card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .unique-section-main-div .unique-card-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .unique-section-main-div .unique-card-container .unique-card {
    width: 100%;
  }
  .unique-section-main-div .unique-card-container .unique-card {
    padding: 1rem 1.2rem;
  }
}
@media (max-width: 567px) {
  .unique-section-main-div .unique-card-container .unique-card {
    padding: 0;
  }
}
