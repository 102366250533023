.nav-menu-main-div {
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 333;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.nav-menu-main-div .menu-content {
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 1.5rem;

  box-sizing: border-box;
  padding-inline: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  backdrop-filter: blur(2px);

  background-color: #000;

  -webkit-box-shadow: 0px 8px 300px 105px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 8px 300px 105px rgba(0, 0, 0, 1);
  box-shadow: 0px 8px 300px 105px rgba(0, 0, 0, 1);
}

.nav-menu-main-div .menu-content .nav-options {
  width: 100%;
  margin-bottom: 1.2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #e25858;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* gap: 1rem; */
}

.nav-menu-main-div .menu-content .nav-options .nav-item {
  width: 10rem;
  border-bottom: 1px dashed #454545;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  color: #fff;

  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;

  text-wrap: nowrap;
}

.nav-menu-main-div .menu-content .user-btns {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  z-index: 3;
}

.nav-menu-main-div .menu-content .user-btns .log-in-btn {
  text-wrap: nowrap;
  border: none;
  background-color: transparent;
  color: #fff;

  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.nav-menu-main-div .menu-content .user-btns .sign-in-btn {
  padding: 1rem 2.5rem;
  text-wrap: nowrap;

  border-radius: 2.125rem;
  border: 1px solid #e25858;
  background: rgba(155, 88, 226, 0.1);
  backdrop-filter: blur(5.849999904632568px);

  color: #fff;
  cursor: pointer;

  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
