@font-face {
  font-family: "Helvetica Now Display";
  src: url(./assets/font/font/HelveticaNowDisplay-Regular.ttf);
}

@font-face {
  font-family: "Helvetica Now Display Bold";
  src: url(./assets/font/font/HelveticaNowDisplay-ExtraBold.ttf);
}

@import url("https://fonts.googleapis.com/css2?family=Anybody:ital,wght@0,100..900;1,100..900&display=swap");

.App {
  text-align: center;
  font-family: "Secondary";
  /* background: radial-gradient(#1b1b1b, #9b58e23d); */
  /* background: radial-gradient(farthest-corner at 40px 40px, #342742, #000000); */
  background: radial-gradient(ellipse at top, #342742, transparent),
    radial-gradient(ellipse at bottom, #000000, #342742);
  position: relative;
  overflow: hidden;

  /* max-width: '120rem'; */
}

.App .main-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

p {
  margin: 0;
}
body::-webkit-scrollbar {
  width: 8px;
  --tw-bg-opacity: 1;
  background-color: #582f83; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-thumb {
  background-color: #000000; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
  /* creates padding around scroll thumb */
}
@media (max-width: 768px) {
  body::-webkit-scrollbar {
    display: none;
  }
}
