.pricing-top-heading {
  border-radius: 5.79413rem;
  border: 2.207px solid #9b58e2;
  background: rgba(28, 28, 28, 0.52);
  color: white;
  font-size: 1.7rem;
  margin: 0 auto;
  padding: 1.2rem 2.2rem;
}

.pricing-top-section {
  margin: 0 auto;
  position: relative;
}
.pricing-top-section::before {
  position: absolute;
  content: "";
  width: 57.6875rem;
  height: 56.5625rem;
  border-radius: 57.6875rem;
  background: rgba(155, 88, 226, 0.6);

  filter: blur(221.8000030517578px);
  z-index: -1;
  top: -100%;
  left: 20%;
}
.pricing-img1,
.pricing-img2,
.pricing-img3,
.pricing-img4,
.pricing-img5 {
  position: absolute;
}
.pricing-img1 {
  width: 13rem;
  right: 15%;
  bottom: 45%;
}
.pricing-img2 {
  width: 9rem;
  right: 22%;
  bottom: 37%;
}
.pricing-img3 {
  width: 14rem;
  left: 12%;
  bottom: 50%;
}

.pricing-img4 {
  width: 7rem;
  left: 20%;
  bottom: 45%;
}
.pricing-img5 {
  width: 7rem;
  right: 17%;
  bottom: 31%;
}
.pricing-top-section .sub-heading {
  margin: 0;
  margin-bottom: 1.37rem;
  color: #f9f5ff;
  text-align: center;
  font-size: 3.63469rem;
  font-style: normal;
  font-weight: 400;
  line-height: 108.591%;
  width: 50%;
  margin: 2rem auto;
  font-family: "Primary";
}
.pricing-top-section .sub-heading span {
  color: #e25858;
}
.pricing-sub-sub-heading {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-family: "Secondary";
  font-size: 1.65544rem;
  font-style: normal;
  font-weight: 400;
  line-height: 131%; /* 2.16863rem */
  width: 35%;
  margin: 0 auto;
}

.pricing-btn {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-left: 15%;
  justify-content: left;
}
.pricing-btn button {
  padding: 0.5em 2rem;
  color: white;
  border: none;
  font-size: 1.3rem;
  border-radius: 1.6875rem;
  border: 3px solid #9b58e2;
  font-family: "Accent";
  background: rgba(155, 88, 226, 0.05);
  cursor: pointer;
}

/* bottom section */

.pricing-bottom-section {
  position: relative;
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  gap: 1rem;
}
.pricing-bottom-section::before {
  position: absolute;
  content: "";
  width: 27.6875rem;
  height: 36.5625rem;
  border-radius: 57.6875rem;
  background: rgba(155, 88, 226, 0.6);

  filter: blur(221.8000030517578px);
  z-index: -1;
  /* top: -100%; */
  left: 0;
}

.pricing-pack-deatils {
  border-radius: 1rem;
  border: 2px solid #9b58e2;

  background: rgba(155, 88, 226, 0.25);
  width: 31.25rem;
  height: 35rem;
}
.pricing-pack-deatils h2 {
  border-radius: 1.5625rem;
  background: #9b58e2;
  width: 60%;
  height: 7%;

  font-size: 1.5rem;
  font-weight: 500;
  padding: 0.5em 1em;
  margin: 2rem auto 2rem 3rem;
}
.pack-details {
  margin: 4rem 3rem;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start;
    align-items: flex-start; */
  gap: 2rem;
  /* padding-left: 4rem; */
}
.pack-details span {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: flex-start; */
  gap: 2rem;
}

/* bottom-right-section */

.pricing-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.pricing-box,
.selected-box {
  width: 41.625rem;
  height: 8.3125rem;

  border-radius: 1rem;
  /* background: rgba(155, 88, 226, 0.25); */
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.pricing-box p,
.selected-box p {
  border-radius: 0.95444rem;
  background: rgba(255, 255, 255, 0.25);
  padding: 0.2rem 0.5rem;
  margin: 0.5rem;
  font-size: 0.91744rem;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  width: 5rem;
  font-family: "Accent";
}
.pricing-box span,
.selected-box span {
  color: #fff;
  text-align: center;
  font-family: "Accent";
  font-size: 1.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: 122.722%;
}

.selected-box {
  background: #e25858;
}
.pricing-box {
  background: rgba(155, 88, 226, 0.25);
}
.pricing-box h3,
.selected-box h3 {
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  font-family: "Accent";
}
.pricing-checked-svg {
  padding: 0.5rem;
  background: #fff;
  border-radius: 50%;
}
.blog-card-div {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 3fr));
  gap: 4rem;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 4rem;
  margin-bottom: 4rem;
  width: 100%;
}
.blog-card-heading {
  color: #fff;
  text-align: left;
  font-family: "Primary";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 122.722%; /* 49.089px */
}
.card {
  width: 100%;

  position: relative;
}
.topic {
  display: flex;
  flex-direction: column;
  gap: 0rem;
  justify-content: right;
  align-items: right;
}
.topic button {
  color: white;
  margin-bottom: 0.3rem;
  border: none;
  padding: 0.5rem 2rem;
  font-size: 16px;
  border-radius: 74.568px;
  border: 1px solid #9b58e2;
  background: #9b58e2;
  font-family: "Accent";
}
.pricing-btn button:focus {
  background: #9b58e2;
  color: white;
}
.blog-div {
  margin-top: 5rem;
  display: flex;
  width: 70%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
}
.innerBlog {
  margin-top: 14rem;
}
.blog-div-content {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.blog-image {
  align-items: flex-start;
  width: 50%;
  height: 60%;

  object-fit: cover;
}
.blog-image .blog-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.text-div-blog {
  align-items: flex-end;
  margin-left: 10%;
}
.tailored {
  color: #e25858;
  text-align: left;
  font-family: "Accent";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 108.591%;
}
.ai-references {
  color: #fff;
  text-align: left;
  font-family: "Primary";
  font-size: 58.155px;
  font-style: normal;
  font-weight: 500;
  width: 80%;
}
.text-lorem {
  color: rgba(255, 255, 255, 0.7);
  font-family: "Accent";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 131%; /* 31.44px */
}
.text-lorem span {
  color: #fff;
  font-size: 35px;
}
.trending-heading {
  color: #fff;
  font-size: 36px;
  font-style: normal;
  text-align: right;
  font-family: "Secondary";
  font-weight: 700;
}
.top-heading-blog {
  color: #9b58e2;
  text-align: right;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.lorem-text {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  text-align: right;
  margin-right: 0%;
}

.image-div {
  width: 100%;
  height: 60%;
  object-fit: cover;
}
.image-div .img {
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
}
.card-content {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
a {
  color: #b90124;
}
.subheading {
  color: #fff;
  text-align: left;
  font-family: "Accent";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 122.722%; /* 33.135px */
}
.shareLogo {
  display: flex;
  gap: 1rem;
  float: right;
}

.text {
  color: rgba(255, 255, 255, 0.7);
  font-family: "Accent";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 131%; /* 18.34px */
}
.show-more-blogs {
  background: none;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  gap: 10px;
  position: relative;
  border-radius: 24px;
  background-color: #9b58e2;
  color: #fff;
}

.show-more-blogs:hover {
  border: 1px solid #9b58e2;
  color: #9b58e2;
  background-color: transparent;
}

@media (max-width: 1400px) {
  .blog-card-heading {
    font-size: 35px;
  }
  .subheading {
    font-size: 17px;
  }
  .ai-references {
    font-size: 50px;
  }
}
@media (max-width: 967px) {
  .pricing-img1,
  .pricing-img2,
  .pricing-img3,
  .pricing-img4,
  .pricing-img5 {
    display: none;
  }
  .blog-div {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .trending-heading {
    text-align: center;
  }
  .text-div-blog {
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }
  .top-heading-blog {
    color: #9b58e2;
    text-align: center;
  }
  .lorem-text {
    text-align: center;
  }
}

/* For screens smaller than 876px */
@media (max-width: 875px) {
  .pricing-bottom-section {
    flex-direction: column-reverse;
  }
  .pricing-btn {
    flex-direction: column;
  }
  .pricing-btn button {
    margin-bottom: 0rem;
  }
  .innerBlog {
    margin-top: 9rem;
  }
  .blog-div {
    width: 100%;
    display: block;
  }
  .blog-image {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .text-div-blog {
    width: 50%;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  .lorem-text {
    font-size: small;
  }
  .top-heading-blog {
    font-size: medium;
  }
  .topic button {
    padding: 0.1rem 0.1rem;
    font-size: small;
  }
  .shareLogo {
    float: left;
    margin-left: 30%;
  }
  .blog-div-content {
    width: 90%;
  }
  .ai-references {
    width: 95%;
    font-size: x-large;
  }
  .text-lorem {
    font-size: small;
  }
  .text-lorem span {
    font-size: medium;
  }
  .blog-card-heading {
    font-size: xx-large;
  }
  .blog-card-div {
    margin-top: 1rem;
    display: block;
  }
  .card {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .pricing-btn {
    gap: 0.7rem;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    margin-left: 0%;
  }
}

/* For screens smaller than 576px */
@media (max-width: 575px) {
  .pricing-top-heading {
    font-size: 1.2rem;
    width: 100%;
  }
  .pricing-top-section .sub-heading {
    font-size: 2rem;
    width: 80%;
  }
  .pricing-sub-sub-heading {
    font-size: 1.5rem;
    width: 80%;
  }
  .pricing-box,
  .selected-box {
    width: 90%;
    margin-left: 0.8rem;
    padding: 0.5rem;
  }
  .pricing-pack-deatils {
    width: 90%;
  }
}
